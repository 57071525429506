import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
// @ts-ignore
import ArrowLeft from '../assets/arrow-left-solid.svg';
import { H3 } from '../design/SharedComponents';

const IconSC = styled.div`
  box-sizing: border-box;
  margin-right: 4px;
  padding: 6px;
  max-height: 37px;
  max-width: 37px;
  min-height: 37px;
  min-width: 37px;
  display: flex;

  color: ${(props) => props.theme.color.grey};

  transition: background-color 0.2s linear;

  border-radius: 100px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

const LinkSC = styled(Link)`
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
  width: fit-content;

  text-decoration: none;

  &:hover > ${IconSC} {
    background-color: #e4e4e4;
  }
`;

type Props = {
  text: string;
  to: string;
};

export const Back = ({ text, to }: Props) => (
  <LinkSC to={to}>
    <IconSC>
      <ArrowLeft />
    </IconSC>
    <H3
      style={{
        marginBlockStart: 0,
        marginBlockEnd: 0,
      }}
    >
      {text}
    </H3>
  </LinkSC>
);
