import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { Text } from '../../design/SharedComponents';
import { theme } from '../../design/theme';
import { Product } from '../../templates/product-page';
// @ts-ignore
import ArrowRight from '../../assets/arrow-right-solid.svg';

const ProductCardSC = styled.div`
  box-sizing: border-box;
  max-width: 400px;
  height: 360px;
  box-shadow: 0px 0px 15px #0000002b;
  overflow: hidden;

  cursor: pointer;

  transition: transform 0.2s;

  &:hover {
    transform: scale(1.01);
  }

  @media only screen and (max-width: 900px) {
    width: 380px;
  }

  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`;

const ImgContainer = styled.div`
  width: auto;
  height: 300px;
  overflow: hidden;

  background: ${theme.color.grey}80;
`;

const TextContainer = styled.div`
  padding: 5px 15px;
  box-sizing: border-box;

  height: 60px;
  display: flex;
  align-items: center;
`;

const TextSC = styled(Text)`
  font-weight: bold;
  margin-block-start: 0;
  margin-block-end: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
`;

const IconSC = styled.div`
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  color: ${({ theme }) => theme.color.black};

  svg {
    width: 20px;
    height: 20px;
  }
`;

const LinkSC = styled(Link)`
  text-decoration: none;
  height: fit-content;

  @media only screen and (max-width: 800px) {
    width: calc(50% - 5px);
  }
`;

const ProductCard = ({
  heroImage,
  slug,
  title,
  type,
}: Pick<Product, 'heroImage' | 'slug' | 'title' | 'type'>) => {
  const image = getImage(heroImage.file);

  return (
    <LinkSC to={`/${type}/${slug}`}>
      <ProductCardSC>
        <ImgContainer>
          <GatsbyImage
            image={image}
            alt={title}
            style={{ width: 'auto', height: '100%' }}
            objectFit="cover"
            loading="lazy"
          />
        </ImgContainer>
        <TextContainer>
          <TextSC>{title}</TextSC>
          <IconSC>
            <ArrowRight />
          </IconSC>
        </TextContainer>
      </ProductCardSC>
    </LinkSC>
  );
};

export default ProductCard;
