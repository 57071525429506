import { graphql } from 'gatsby';
import { ImageDataLike } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';
import { Back } from '../components/Back';
import SiteTemplate from '../components/pagesTemplate/SiteTemplate';
import ProductCard from '../components/product/ProductCard';
import ProductMetaTags from '../components/ProductMetaTags';

interface ImageFile {
  file: ImageDataLike;
}

export interface Product {
  id: string;
  description: string;
  categories: Array<{ name: string }>;
  slug: string;
  title: string;
  heroImage: ImageFile;
  images: ImageFile[];
  type: string;
}

export const query = graphql`
  query AllProducts($type: String!) {
    allStrapiProduct(
      filter: { type: { eq: $type } }
      sort: { order: ASC, fields: title }
    ) {
      edges {
        node {
          slug
          description
          title
          id
          type
          heroImage {
            file {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  aspectRatio: 1.33
                )
              }
            }
          }
          images {
            file {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  aspectRatio: 1.33
                )
              }
            }
          }
          categories {
            name
          }
        }
      }
    }
  }
`;

interface Props {
  data: {
    allStrapiProduct: {
      edges: Array<{
        node: Product;
      }>;
    };
  };
}

const PageContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
`;

const ProductsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
  max-width: 1240px;
  min-width: 50vw;

  justify-content: center;

  @media only screen and (max-width: 800px) {
    gap: 10px;
  }
`;

const ProductPage = ({ data }: Props) => {
  const products = data.allStrapiProduct.edges.map((edge) => edge.node);

  const title =
    products[0]?.type === 'produkty' ? 'Naša ponuka' : 'Naše služby';

  return (
    <SiteTemplate>
      <ProductMetaTags
        title={title}
        keywords={['produkty', 'sluzby', 'ocel', 'zabradila', 'cnc', 'laser']}
        description="Stránka s našimi produktmi a službami"
      />

      <Back to="/#home" text={title} />
      <PageContainer>
        <ProductsContainer>
          {products.map((product) => (
            <ProductCard
              key={product.slug}
              heroImage={product.heroImage}
              slug={product.slug}
              title={product.title}
              type={product.type}
            />
          ))}
        </ProductsContainer>
      </PageContainer>
    </SiteTemplate>
  );
};

export default ProductPage;
